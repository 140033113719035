<template>
  <div class="accordion">
    <b-card no-body class="mb-1">
      <b-card-header header-tag="header" class="p-1" role="tab">
        <b-button block size="sm" variant="#F3F6F9" @click="open = !open">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <span class="font-weight-bolder">{{ getDay }}</span>
            <span class="text-success font-weight-bolder">
              Tổng kcal: {{ round(item.totalKcal, 1) }}
            </span>
            <v-icon>mdi-{{ open ? 'chevron-up' : 'chevron-down' }}</v-icon>
          </div>
        </b-button>
      </b-card-header>
      <b-collapse
        :id="`accordion-${id}`"
        v-model="open"
        visible
        accordion="my-accordion"
        role="tabpanel"
      >
        <b-card-body>
          <div
            v-for="(time, index) in item.timeGroups"
            :key="index"
            class="mb-5"
          >
            <div class="d-flex justify-content-between align-items-center mb-5">
              <b-row class="font-weight-bolder">
                <b-col cols="2"
                  >{{ time.timeName }}<span class="text-danger">*</span></b-col
                >
                <b-col v-if="time.defaultFood && time.defaultFood.length"
                  ><span class="text-success"
                    >{{ round(time.totalKcal, 1) }}
                  </span>
                  <span class="ml-1 text-dark-50">kcal</span>
                </b-col>
              </b-row>
              <b-button
                v-if="!isDetail"
                class="btn btn-success"
                @click="addFood(time, 0)"
              >
                Thêm món ăn
              </b-button>
            </div>
            <table-menu
              :is-detail="isDetail"
              :is-active="isActive"
              :foods="time.defaultFood"
              @edit="editFood"
              @add-replace="addFoodRelace"
              @edit-replace="editFoodRelace"
            />
            <div
              v-if="showDesserts(time)"
              class="d-flex justify-content-between align-items-center my-5"
            >
              <b-row>
                <b-col cols="12">Món tráng miệng (nếu có)</b-col>
              </b-row>
              <b-button
                v-if="!isDetail"
                class="btn btn-success"
                @click="addFood(time, 1)"
              >
                Thêm món ăn
              </b-button>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <modal-food
      :type="typeModal"
      :id="id"
      :food-id="foodId"
      @hide="hideModal"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import {
  SET_MODAL,
  SET_BODY_FOOD_TEMPLATE,
} from '@/core/services/store/menu.module';
const { mapMutations, mapState } = createNamespacedHelpers('menu');
import { round } from 'lodash';
export default {
  name: 'CollapseMenu',
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    id: {
      type: [String, Number],
      default: null,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    'table-menu': () => import('./Table.vue'),
    'modal-food': () => import('./ModalFood.vue'),
  },
  data() {
    return {
      open: false,
      days: [
        {
          name: 'Thứ hai',
          value: 'T2',
        },
        {
          name: 'Thứ ba',
          value: 'T3',
        },
        {
          name: 'Thứ tư',
          value: 'T4',
        },
        {
          name: 'Thứ năm',
          value: 'T5',
        },
        {
          name: 'Thứ sáu',
          value: 'T6',
        },
        {
          name: 'Thứ bảy',
          value: 'T7',
        },
        {
          name: 'Chủ nhật',
          value: 'T8',
        },
      ],
      typeModal: null,
      foodId: null,
      round,
    };
  },
  computed: {
    ...mapState(['menuDetail']),
    getDay() {
      const day = this.days.find((el) => el.value === this.item.dateCode);
      return day.name;
    },
  },
  methods: {
    ...mapMutations({ SET_MODAL, SET_BODY_FOOD_TEMPLATE }),
    addFood(time, foodType) {
      this.SET_BODY_FOOD_TEMPLATE({
        timeCode: time.timeCode,
        dateCode: this.item.dateCode,
        foodType,
      });
      this.typeModal = 'create';
      this.$bvModal.show(`food-modal-${this.id}`);
    },
    showDesserts(time) {
      if ([2, 3].includes(time.timeCode)) return true;
      return false;
    },
    editFood(id) {
      this.foodId = id;
      this.typeModal = 'edit';
      this.$bvModal.show(`food-modal-${this.id}`);
    },
    editFoodRelace(params) {
      this.foodId = params.id;
      this.SET_BODY_FOOD_TEMPLATE({
        parentId: params.parentId,
        foodType: 2,
      });
      this.typeModal = 'edit';
      this.$bvModal.show(`food-modal-${this.id}`);
    },
    addFoodRelace({ id, parentId }) {
      this.foodId = id;
      this.SET_BODY_FOOD_TEMPLATE({
        foodType: 2,
        parentId: parentId,
        dateCode: this.item.dateCode,
      });
      this.typeModal = 'create';
      this.$bvModal.show(`food-modal-${this.id}`);
    },
    hideModal() {
      this.foodId = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-success {
  color: #21a567 !important;
}
</style>
